import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { DigitalWallets } from '@latitude/digital-wallets';
import { Link } from '@latitude/link';
import { Horizontal } from '@latitude/spacing';

import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import FilterList from '@/components/FilterList/FilterList';
import PageData from '@/data/pages/credit-cards/index.json';
import { COLOR } from '@/utils/constants';
import {
  BUTTON_STYLE,
  BREAKPOINT,
  MARGIN
} from '@latitude/core/utils/constants';
import { Box } from '@/components/Box/Box';
import { HeroImage } from '@/components/HeroBranded/HeroBranded';

import HeroBranded from '@/components/HeroBranded/HeroBranded';

import heroImage from './images/go-hero-banner.webp';
import shoppingBanner from './images/shopping-banner-go.webp';

import Section from '@latitude/section';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import BrandedCallout from './_callout';
import Partners from './Partners';
import HowToApply from './_how-to-apply';
import RatesAndFees from './_rates-and-fees';
import ExpediaWotifSection from './_expedia-wotif';
import WhyChoose from './_why-choose';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import Button from '@/components/Button/Button';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '@/components/PaymentOptions/PaymentOptions';
import { LFS_APP } from '../../../utils/constants';
import Text from '@/components/Text/Text';
import SvgInline from '@/components/SvgInline/SvgInline';
import { Strong } from '@/components/Text/Bold';

import { PageContext } from '@/context/PageContext';
import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import GoMicrositeHeader from './_go-microsite-header';
import { NotificationBanner } from '@latitude/banner';

const APPLY_NOW_URL = 'https://cards.latitudefinancial.com/go';
const HEADER_ITEMS = [
  {
    title: 'Turn your everyday shopping into $200.',
    text:
      'After you spend $1,500 on everyday purchases each month in the first 3 months with your Latitude GO Platinum Mastercard credit card*.',
    termsconds:
      '*Excludes long-term Interest Free payment plans. Other exclusions apply. New & approved customers only. Limited to one offer per customer. T&Cs, card fee & other charges apply. Offer ends 31st October 2024.',
    headerImage: shoppingBanner,
    urlFindOutMore: 'Apply now',
    urlApplyNow: APPLY_NOW_URL
  },
  {
    title: 'Break up your bigger buys',
    text:
      'With long-term Interest Free payment plans from 6-60 months at hundreds of retailers.',
    termsconds: '',
    headerImage: heroImage,
    urlFindOutMore: '',
    urlApplyNow: APPLY_NOW_URL
  }
];
const PAGE_NAVIGATION = [
  {
    anchor: 'why-us',
    label: 'Why us?'
  },
  {
    anchor: 'rates-and-fees',
    label: 'Rates & Fees'
  },
  {
    anchor: 'how-to-apply',
    label: 'How to apply'
  },
  {
    anchor: 'eligibility',
    label: 'Eligibility'
  },
  {
    anchor: 'payment',
    label: 'Payment options'
  },
  {
    anchor: 'digital-wallets',
    label: 'Digital wallets'
  }
];
const IMPORTANT_INFO = {
  content: {
    importantInformationSectionOne: [
      '<sup>&reg;</sup>Mastercard is a registered trademark and the circles design is a trademark of Mastercard International Incorporated.',
      '<sup>1</sup>To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay the full closing balance (excluding un-expiring Interest Free Payment Plans) on each statement of account by the applicable due date.',
      'Samsung and Samsung Pay are trademarks or registered trademarks of Samsung Electronics Co., Ltd.'
    ],
    importantInformationSectionTwo: [
      'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S. Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.',
      'Google Pay, Android, the Google Pay Logo, Google Play and the Google Play logo are trademarks of Google LLC.',
      '&reg; Registered to BPAY Pty Ltd ABN 69 079 137 518',
      'Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad, iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App Store is a service mark of Apple Inc., registered in the U.S. and other countries.',
    ]
  }
};

function GoMastercardHomePage(props) {
  const gatsbyTealiumEnv = process.env.GATSBY_TEALIUM_ENV || 'unknown';
  const dynamicYieldKey = gatsbyTealiumEnv === 'prod' ? '8790471' : '8790470';

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 130px !important;
          background-color: #f8f8f8;

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gomastercard/"
          />
          <title>Credit Cards | Latitude Financial</title>
          <meta name="description" content={PageData.metaDesc} />

          <link rel="preconnect" href="//cdn.dynamicyield.com" />
          <link rel="preconnect" href="//st.dynamicyield.com" />
          <link rel="preconnect" href="//rcom.dynamicyield.com" />
          <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
          <link rel="dns-prefetch" href="//st.dynamicyield.com" />
          <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
          <script type="text/javascript">
            {`
              window.DY = window.DY || {};
              DY.recommendationContext = { type: "PRODUCT", data: ['gomastercard'] };
            `}
          </script>
          <script
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldKey}/api_dynamic.js`}
          />
          <script
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldKey}/api_static.js`}
          />
        </Helmet>
        <GoMicrositeHeader data={state.secondaryNavData} />
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
        )}

        <div
          className="d-none d-lg-block"
          css="position:relative; z-index:10;
                 .sticky-navigation--fixed{
                  top:40px !important;
                }"
        >
          <StickyNavigationBranded
            items={PAGE_NAVIGATION}
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        {/* <FeeChangesCCSection
          heading="Notice of Variation to your Latitude GO Mastercard credit card contract"
          body="Effective from 17 September 2024, we are changing the credit card annual percentage rate on purchases from 27.49% p.a. to 27.99% p.a."
          link="https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Credit-Card-Pricing-Changes"
        /> */}

        <WhyChoose />
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <ExpediaWotifSection id="expedia-wotif" />
        )}
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees />
        )}
        <HowToApply />
        <Partners />
        <Section
          heading="Payment Options"
          id="payment"
          className="section--payment-options"
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: LFS_APP.APP_STORE
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: LFS_APP.GOOGLE_PLAY
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Online Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons>
                        <Horizontal spacing={MARGIN.M8}>
                          <Link
                            target="_blank"
                            button={BUTTON_STYLE.SECONDARY}
                            href="https://servicecentre.latitudefinancial.com.au/login"
                            trackId="payment-options-service-center-login-link"
                            width="156px"
                          >
                            Login
                          </Link>
                          <Link
                            target="_blank"
                            button={BUTTON_STYLE.TERTIARY}
                            href="https://servicecentre.latitudefinancial.com.au/register"
                            trackId="payment-options-service-center-register-link"
                            width="156px"
                          >
                            Register
                          </Link>
                        </Horizontal>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click 'Pay My Account'</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take <em>3 to 5 business days</em> to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'bpay',
                title:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude GO Mastercard Biller Code (443887)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY
                        <sup>&reg;</sup> on your due date, you must complete the
                        transaction before your bank’s cut off time, otherwise
                        the transaction may be processed on the following day.
                        Some BPAY<sup>&reg;</sup> payments can take up to three
                        days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            tabContentMinHeight="385px"
          />
        </Section>
        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />
        <BrandedCallout />

        <ImportantInformation
          data={IMPORTANT_INFO}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          css={`
            && {
              background-color: ${COLOR.GREY10};
            }
          `}
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

const StyledHeroBranded = styled(HeroBranded)`
  ${HeroImage} {
    height: 310px;

    @media (min-width: ${BREAKPOINT.MD}) {
      height: auto;
    }
  }
`;

const CardContainer = styled(Box)`
  position: relative;
  margin: 0 auto;
`;

const BrandedFilterList = styled(FilterList)`
  .dropdown-container-fixed {
    // mobile version
    width: 100%;
    max-width: 400px;
    margin-left: 0;
  }

  .dropdown::-ms-expand {
    display: none;
  }

  .dropdown-container {
    // desktop version
    position: absolute;
    left: 0;
    display: block;
    width: 255px;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    &:last-child {
      padding-top: 100px;
    }
  }
`;

const AngleWhite = styled.div`
  position: absolute;
  width: 100%;
  height: 300%;
  background-color: #fff;
  transform: skew(0deg, 4deg);
  transform-origin: top right;
`;
const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

export default GoMastercardHomePage;
